import UserProfile from '../UserProfile/UserProfile.vue'

export default {
  name: 'InfoCreditCard',
  components: {
    UserProfile,
  },
  props: {
    descriptionText: {
      type: String,
      required: false,
    },
    payment: {
      type: Object,
      required: true,
    },
  },
  computed: {
    description () {
      return this.descriptionText ?? this.$t('Carga de saldo inmediata')
    },

  },
  methods: {

  },
}
